.login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;

  input {
    width: 98%;
  }

  form {
    @include default-form;
    font-family: "Roboto Condensed", sans-serif;
    color: $bottega-green;
  }
}
