.single-student-wrapper {
  display: flex;
  justify-content: space-evenly;
  margin: 50px;
}

.task-list-wrapper {
  margin: 0 50px;

  ul {
    list-style-type: none;

    li {
      margin: 7px;
    }
  }
}

@media (max-width: 1100px) {
  .single-student-wrapper {
    flex-direction: column;
    align-items: center;
    margin: 0;
  }
}
