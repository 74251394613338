.skill-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  text-align: center;
  background-image: linear-gradient(to right, $light-gradient, $dark-gradient);
  z-index: 0;
  border-radius: 20px;
  position: relative;
  border-right: 1px solid $main-gray;
  border-left: 1px solid $main-gray;

  .skill-bar {
    height: 100%;
    background-color: $secondary-gray;
    position: absolute;
    z-index: -1;
    border-radius: 0 20px 20px 0;
    align-self: flex-end;
  }

  .skill {
    text-align: right;
  }
}
