.admin-nav-buttons {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 250px;

  button {
    width: 70px;
    background-color: $main-gray;
    cursor: pointer;

    &:active {
      background-color: black;
    }
  }
}

.student-card-wrapper {
  display: grid;
  border: 1px solid $secondary-gray;
  align-items: center;
  grid-template-columns: 300px 1fr;
  grid-row-gap: 20px;
  grid-column-gap: 40px;
  width: 70%;
  padding: 25px;
  margin-bottom: 20px;
  border-radius: 5px;
  box-shadow: 7px 7px 14px 2px rgba(225, 225, 225, 1);

  .naming-wrapper {
    display: flex;
    align-items: center;

    .linkedin-link,
    .github-link {
      margin-left: 20px;
      height: 45px;
      width: auto;

      img {
        height: 100%;
        width: auto;
      }
    }

    .github-link {
      margin-left: 10px;
    }
  }

  img {
    justify-self: center;
    width: 100%;
    height: auto;
  }

  .scale-title {
    margin-bottom: -20px;
    margin-left: 3px;
    display: flex;
  }

  .skill-legend {
    grid-column: 1;
    background-image: linear-gradient(
      to right,
      $light-gradient,
      $dark-gradient
    );
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
    text-align: right;
    margin-top: 3px;
    border-left: 1px solid $main-gray;
    border-right: 1px solid $main-gray;

    span {
      width: 20%;
      padding: 3px;
    }
  }

  .skills {
    display: grid;
    grid-column: 1/-1;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 15px;
    grid-column-gap: 30px;
    // color: #f3f3f3;

    .scale-title {
      color: black;
    }
  }

  .admin-control-buttons {
    display: flex;
    width: 260px;

    button {
      background-color: $main-gray;
      margin-right: 10px;
      cursor: pointer;
      padding: 0 15px 0 15px;
      width: auto;

      &:active {
        background-color: black;
      }
    }
  }
}

@media (max-width: 800px) {
  .student-card-wrapper {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr auto;
    width: 80%;

    .skills {
      grid-template-columns: 1fr;
    }

    .naming-wrapper {
      display: flex;
      justify-content: center;
    }
  }
}
