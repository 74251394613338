.skill-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  text-align: center;
  background-image: linear-gradient(to right, #00e286, #009154);
  z-index: 0;
  border-radius: 20px;
  position: relative;
  border-right: 1px solid #444;
  border-left: 1px solid #444;
}
.skill-wrapper .skill-bar {
  height: 100%;
  background-color: #e1e1e1;
  position: absolute;
  z-index: -1;
  border-radius: 0 20px 20px 0;
  align-self: flex-end;
}
.skill-wrapper .skill {
  text-align: right;
}

.admin-nav-buttons {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 250px;
}
.admin-nav-buttons button {
  width: 70px;
  background-color: #444;
  cursor: pointer;
}
.admin-nav-buttons button:active {
  background-color: black;
}

.student-card-wrapper {
  display: grid;
  border: 1px solid #e1e1e1;
  align-items: center;
  grid-template-columns: 300px 1fr;
  grid-row-gap: 20px;
  grid-column-gap: 40px;
  width: 70%;
  padding: 25px;
  margin-bottom: 20px;
  border-radius: 5px;
  box-shadow: 7px 7px 14px 2px rgb(225, 225, 225);
}
.student-card-wrapper .naming-wrapper {
  display: flex;
  align-items: center;
}
.student-card-wrapper .naming-wrapper .linkedin-link,
.student-card-wrapper .naming-wrapper .github-link {
  margin-left: 20px;
  height: 45px;
  width: auto;
}
.student-card-wrapper .naming-wrapper .linkedin-link img,
.student-card-wrapper .naming-wrapper .github-link img {
  height: 100%;
  width: auto;
}
.student-card-wrapper .naming-wrapper .github-link {
  margin-left: 10px;
}
.student-card-wrapper img {
  justify-self: center;
  width: 100%;
  height: auto;
}
.student-card-wrapper .scale-title {
  margin-bottom: -20px;
  margin-left: 3px;
  display: flex;
}
.student-card-wrapper .skill-legend {
  grid-column: 1;
  background-image: linear-gradient(to right, #00e286, #009154);
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  text-align: right;
  margin-top: 3px;
  border-left: 1px solid #444;
  border-right: 1px solid #444;
}
.student-card-wrapper .skill-legend span {
  width: 20%;
  padding: 3px;
}
.student-card-wrapper .skills {
  display: grid;
  grid-column: 1/-1;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 15px;
  grid-column-gap: 30px;
}
.student-card-wrapper .skills .scale-title {
  color: black;
}
.student-card-wrapper .admin-control-buttons {
  display: flex;
  width: 260px;
}
.student-card-wrapper .admin-control-buttons button {
  background-color: #444;
  margin-right: 10px;
  cursor: pointer;
  padding: 0 15px 0 15px;
  width: auto;
}
.student-card-wrapper .admin-control-buttons button:active {
  background-color: black;
}

@media (max-width: 800px) {
  .student-card-wrapper {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr auto;
    width: 80%;
  }
  .student-card-wrapper .skills {
    grid-template-columns: 1fr;
  }
  .student-card-wrapper .naming-wrapper {
    display: flex;
    justify-content: center;
  }
}
.student-form-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: #00cb78;
  font-family: "Roboto Condensed", sans-serif;
}
.student-form-wrapper .student-form {
  display: grid;
  border: 1px solid #e1e1e1;
  grid-template-columns: 1fr;
  grid-row-gap: 20px;
  grid-column-gap: 50px;
  width: 60%;
  padding: 25px;
  margin-bottom: 20px;
  border-radius: 5px;
  box-shadow: 7px 7px 14px 2px rgb(225, 225, 225);
  text-align: center;
  margin-top: 20px;
}
.student-form-wrapper .student-form .student-names input {
  margin-bottom: 10px;
}
.student-form-wrapper .student-form select {
  width: 80%;
  margin-bottom: 10px;
  border: 1px solid #444;
  outline: none;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 14px;
}
.student-form-wrapper .student-form select:focus {
  border: 1px solid #00cb78;
}
.student-form-wrapper .student-form textarea {
  border-radius: 30px;
  padding: 20px;
  border: 1px solid #444;
  outline: none;
}
.student-form-wrapper .student-form textarea:focus {
  border: 1px solid #00cb78;
}
.student-form-wrapper .student-form .skill-level-inputs {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 50px;
  grid-row-gap: 10px;
  justify-items: center;
}

.login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
}
.login input {
  width: 98%;
}
.login form {
  display: grid;
  border: 1px solid #e1e1e1;
  grid-template-columns: 1fr;
  grid-row-gap: 20px;
  grid-column-gap: 50px;
  width: 60%;
  padding: 25px;
  margin-bottom: 20px;
  border-radius: 5px;
  box-shadow: 7px 7px 14px 2px rgb(225, 225, 225);
  text-align: center;
  font-family: "Roboto Condensed", sans-serif;
  color: #00cb78;
}

.titles {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.titles .certification-list {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.titles .certification-list ul {
  list-style-type: circle;
}
.titles .certification-list h2 {
  text-align: center;
}

.accreditor-bullet {
  width: 90vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.accreditor-bullet img {
  width: auto;
  height: 25px;
  margin-right: 10px;
}

.rubric {
  display: grid;
  border: 1px solid #e1e1e1;
  grid-template-columns: 1fr;
  grid-row-gap: 20px;
  grid-column-gap: 50px;
  width: 60%;
  padding: 25px;
  margin-bottom: 20px;
  border-radius: 5px;
  box-shadow: 7px 7px 14px 2px rgb(225, 225, 225);
  text-align: center;
  background-color: white;
  text-align: left;
  width: 70%;
  position: sticky;
  margin-top: 30px;
  padding: 0px;
  top: 5px;
  z-index: 1;
  cursor: pointer;
}
.rubric li {
  padding: 10px;
}
.rubric .divider {
  margin-left: 10px;
  margin-right: 10px;
}
.rubric .rubric-item-title {
  color: #00cb78;
  margin-left: 10px;
}
.rubric .collapsed-rubric {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  padding: 10px;
}
.rubric img {
  height: 20px;
  width: auto;
  margin-right: 10px;
  position: absolute;
  top: 12px;
  right: 10px;
  transform: rotate(90deg);
  animation: ease-in-out 0.3s;
}

.single-student-wrapper {
  display: flex;
  justify-content: space-evenly;
  margin: 50px;
}

.task-list-wrapper {
  margin: 0 50px;
}
.task-list-wrapper ul {
  list-style-type: none;
}
.task-list-wrapper ul li {
  margin: 7px;
}

@media (max-width: 1100px) {
  .single-student-wrapper {
    flex-direction: column;
    align-items: center;
    margin: 0;
  }
}
body {
  margin: 0;
}

.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Roboto Condensed", sans-serif;
}
.home .navbar {
  height: 50px;
  width: 100vw;
  border-bottom: 1px solid #e1e1e1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.home .navbar img {
  width: 158.14px;
  height: auto;
  padding-top: 6px;
  padding-left: 20px;
}
.home .navbar .home-button {
  margin-right: 20px;
}

a {
  color: #444;
  text-decoration: none;
  border-radius: 30px;
  font-size: 25px;
}
a:hover {
  color: #00cb78;
}

button {
  background-color: #00cb78;
  height: 30px;
  border: none;
  border-radius: 30px;
  width: 100%;
  color: white;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 16px;
  outline: none;
}
button:hover {
  cursor: pointer;
}
button:active {
  background-color: #01bc70;
}

input {
  height: 20px;
  width: 100%;
  padding: 5px;
  border: 1px solid #444;
  border-radius: 30px;
  outline: none;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 14px;
}
input:focus {
  border: 1px solid #00cb78;
}

.modal {
  font-size: 15px;
}
.modal h3 {
  color: #00cb78;
}

hr {
  width: 90vw;
  margin: 50px;
}