.student-form-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: $bottega-green;
  font-family: "Roboto Condensed", sans-serif;

  .student-form {
    @include default-form;
    margin-top: 20px;

    .student-names {
      input {
        margin-bottom: 10px;
      }
    }

    select {
      width: 80%;
      margin-bottom: 10px;
      border: 1px solid $main-gray;
      outline: none;
      font-family: "Roboto Condensed", sans-serif;
      font-size: 14px;

      &:focus {
        border: 1px solid $bottega-green;
      }
    }

    textarea {
      border-radius: 30px;
      padding: 20px;
      border: 1px solid $main-gray;
      outline: none;

      &:focus {
        border: 1px solid $bottega-green;
      }
    }

    .skill-level-inputs {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-column-gap: 50px;
      grid-row-gap: 10px;
      justify-items: center;
    }
  }
}
