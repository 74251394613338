.titles {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;

  .certification-list {
    display: flex;
    flex-direction: column;
    align-items: center;

    ul {
      list-style-type: circle;
    }

    h2 {
      text-align: center;
    }
  }
}

.accreditor-bullet {
  width: 90vw;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: auto;
    height: 25px;
    margin-right: 10px;
  }
}
